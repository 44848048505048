<template>
  <!-- 代理商列表页面 -->
  <div class="container">
    <a-row>
      <a-col :span="24" class="right">
        <div class="right-content">
          <!-- 筛选 -->
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="40">
                <!-- 状态 -->
                <a-col :md="6" :sm="24">
                  <a-form-item label="类型">
                    <a-select v-model="queryParam.userType" allow-clear placeholder="请选择">
                      <a-select-option :value="1">用户</a-select-option>
                      <a-select-option :value="4">企业</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <!-- 代理商姓名 -->
                <a-col :md="6" :sm="24">
                  <a-form-item label="名称">
                    <a-input v-model="queryParam.userName" allow-clear placeholder="请输入" />
                  </a-form-item>
                </a-col>
                <!-- 按钮 -->
                <a-col :md="6" :sm="24">
                  <a-button type="primary" @click="initTableData">搜索</a-button>
                  <a-button style="margin-left: 8px" @click="() => ((queryParam = {}), initTableData())">重置</a-button>
                </a-col>
              </a-row>
            </a-form>
          </div>
          <!-- 表格 -->
          <a-table
            :columns="columns"
            :data-source="data"
            :scroll="{ x: 1300 }"
            :loading="loading"
            :pagination="false"
            rowKey="id"
          >
            <span slot="userType" slot-scope="text">
              {{ +text === 4 ? '企业发放' : '个人发放' }}
            </span>
            <!-- 操作插槽 -->
            <span slot="action" slot-scope="text, record">
              <a @click="$refs.detailForm.detail(record)">详情</a>
              <a-divider type="vertical" />
              <a @click="$refs.redeemCardDetail.init(record)">明细</a>
            </span>
          </a-table>
          <!-- 分页组件 -->
          <div style="margin-top: 20px; display: flex; flex-direction: row-reverse">
            <a-pagination
              :pageSize="page.pageSize"
              :current="page.pageNo"
              show-size-changer
              :page-size-options="['10', '20', '50', '100']"
              show-quick-jumper
              :total="total"
              :show-total="(total) => `共 ${total} 条`"
              @change="pageChange"
              @showSizeChange="sizeChange"
            ></a-pagination>
          </div>
        </div>
      </a-col>
    </a-row>
    <!-- 引入子组件 -->
    <detail-form ref="detailForm"></detail-form>
    <redeem-card-detail ref="redeemCardDetail" />
  </div>
</template>

<script>
import detailForm from './detailForm.vue'
import redeemCardDetail from './redeemCardDetail.vue'
import { exchangeSendRecordPage } from '@/api/modular/mallLiving/recharge'
const columns = [
  {
    title: '序号',
    width: '100px',
    align: 'center',
    customRender: (text, record, index) => `${index + 1}`,
  },
  {
    title: '类型',
    width: '100px',
    dataIndex: 'userType',
    key: 'name',
    align: 'center',
    scopedSlots: { customRender: 'userType' },
  },
  {
    title: '名称',
    width: '130px',
    dataIndex: 'name',
    align: 'center',
  },
  {
    title: '企业联系人',
    width: '150px',
    dataIndex: 'linkName',
    align: 'center',
  },
  {
    title: '企业账号',
    align: 'center',
    width: '150px',
    dataIndex: 'account',
  },
  {
    title: '发放数量',
    dataIndex: 'number',
    width: '130px',
    align: 'center',
  },
  {
    title: '发放面值',
    dataIndex: 'integral',
    width: '100px',
    align: 'center',
  },

  {
    title: '发放时间',
    dataIndex: 'createTime',
    width: '150px',
    key: 'createTime',
    align: 'center',
  },
  {
    title: '操作',
    width: '130px',
    align: 'center',
    fixed: 'right',
    dataIndex: 'action',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
]

export default {
  data() {
    return {
      columns,
      data: [],
      page: {
        pageNo: 2,
        pageSize: 10,
      },
      total: 0,
      recordItem: null,
      loading: false,
      queryParam: {},
      videoTypeList: [], //视频分类数据
      provinceList: [], //省份列表
      cityList: [], //市区列表
    }
  },
  components: {
    detailForm,
    redeemCardDetail,
  },
  mounted() {
    this.initTableData()
  },
  methods: {
    //切换页面
    pageChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    sizeChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    initTableData() {
      this.page.pageNo = 1
      this.getList()
    },
    getList() {
      this.loading = true
      var params = {
        page: this.page,
        queryParam: this.queryParam,
      }
      exchangeSendRecordPage(params)
        .then((res) => {
          if (res.success) {
            this.data = res.data.rows
            this.data.forEach((item) => {
              item.checked = item.delFlag == 1
            })
            this.total = res.data.totalRows
            this.loading = false
          }
        })
        .finally((res) => {
          setTimeout(() => {
            this.loading = false
          }, 5000)
        })
    },
  },
}
</script>

<style lang="less" scoped>
.container {
  min-width: 1000px;
}
.title {
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  font-weight: bold;
}
.right {
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 100);
}
.right-content {
  box-sizing: border-box;
  padding: 10px;
}
.icon {
  font-size: 20px;
  margin: 0 5px;
}
img {
  width: 20px;
  height: 20px;
}
</style>
